<nav class="sidebar sidebar-offcanvas" id="sidebar">
  <ul class="nav">
    <li class="nav-item nav-profile">
      <a href="#" class="nav-link">
        <div class="nav-profile-image">
          <img [src]="image" alt="profile" />
          <span class="login-status online"></span>
        </div>
        <div class="nav-profile-text d-flex flex-column">
          <span class="font-weight-bold mb-2">{{first_name}} {{last_name}}</span>
          <span class="text-secondary text-small">{{profile}}</span>
        </div>
        <i class="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
      </a>
    </li>
    <!-- Navigation items here -->
    <ng-container *ngIf="profile=='admin'">
      <li class="nav-item">
        <a class="nav-link" routerLink="/home">
          <span class="menu-title">Acceuil</span>
          <i class="mdi mdi-home menu-icon"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/list-employees">
          <span class="menu-title">Liste employés</span>
          <i class="mdi mdi-list-box-outline menu-icon"></i>
        </a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" routerLink="/add-employee">
          <span class="menu-title">Ajout employé</span>
          <i class="mdi mdi-account-plus-outline menu-icon"></i>
        </a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link" routerLink="/list-scoring">
          <span class="menu-title">Pointage </span>
          <i class="mdi mdi-timer-check-outline menu-icon"></i>
        </a>
      </li>
    </ng-container>
    <ng-container *ngIf="profile=='superadmin'">
      <li class="nav-item">
        <a class="nav-link" routerLink="/super-home">
          <span class="menu-title">Acceuil</span>
          <i class="mdi mdi-home menu-icon"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/list-entreprise">
          <span class="menu-title">Liste entreprises</span>
          <i class="mdi mdi-office-building-outline menu-icon"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/ajout-entreprise">
          <span class="menu-title">Ajout entreprises</span>
          <i class="mdi mdi-office-building-plus-outline menu-icon"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/add-employee">
          <span class="menu-title">Ajout employé</span>
          <i class="mdi mdi-account-plus-outline menu-icon"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/rbac">
          <span class="menu-title">Gérer les Accès</span>
          <i class="mdi mdi-account-key menu-icon"></i>
        </a>
      </li>
    </ng-container>

    <li class="nav-item">
      <a class="nav-link" routerLink="/setting">
        <span class="menu-title">Parametre </span>
        <i class="mdi mdi-cog menu-icon"></i>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" (click)="logout()">
        <span class="menu-title">Deconnexion </span>
        <i class="mdi mdi-exit-to-app menu-icon"></i>
      </a>
    </li>
  </ul>
</nav>

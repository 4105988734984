import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.development';
import { map } from 'rxjs/operators';
import { jwtDecode } from 'jwt-decode';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (error) {
      return null;
    }
  }

  authenticate(email: string, password: string) {
    const headers = new HttpHeaders({
      'X-Device-Type': 'browser',
  });
    return this.http
      .post<any>(`${environment.apiUrl}/login`, { email, password },{headers})
      .pipe(
        map((user: any) => {
          try {
            const token=user.token as string;
            const tokenInfo = this.getDecodedAccessToken(token) ;

            sessionStorage.setItem('profile', tokenInfo.profile)
            sessionStorage.setItem('first_name', tokenInfo.first_name)
            sessionStorage.setItem('last_name', tokenInfo.last_name)
            sessionStorage.setItem('id_employee', tokenInfo._id)
            sessionStorage.setItem('company', tokenInfo.company)
            sessionStorage.setItem('is_archived', tokenInfo.is_archived)

          } catch (error) {

          }
          return user;
        })
      );
  }

  changePasswordAdmin(id: string, newPassword: string): Observable<any> {
    const url = `${environment.apiUrl}/changePassword`;
    const body = { id, newPassword };
    return this.http.put(url, body);
  }
  updateEmployeeRole(id: string, profile: string): Observable<any> {
    const url = `${environment.apiUrl}/changeProfile`;
    const body = { id, profile };
    return this.http.put(url, body);
  }
  changePassword(id:string,oldPassword:string,newPassword:string) {
    return this.http
    .put<any>(`${environment.apiUrl}/changePassword`,{
      oldPassword: oldPassword,
      newPassword: newPassword,
      id:id
    }
    )
    .pipe(
      map((userData: any )=>{
      return userData
      })
    )
}


createUser( data:any) {
  return this.http.post<any>(`${environment.apiUrl}/createUser`, data)
}

  isUserloggedIn() {
    const user = sessionStorage.getItem('token');
    return !(user === null);
  }
  logout(userId:any)
  {
    const headers = new HttpHeaders({
      'X-Device-Type': 'browser',
  });
console.log(userId);
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('profile');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('last_name');
    sessionStorage.removeItem('first_name');
    sessionStorage.removeItem('company');
    sessionStorage.removeItem('is_archived');
    sessionStorage.removeItem('id');
    return this.http.post<any>(`${environment.apiUrl}/logout`, { userId },{headers});

  }
  // logout() {
  //   sessionStorage.removeItem('token');
  //   sessionStorage.removeItem('profile');
  //   sessionStorage.removeItem('email');
  //   sessionStorage.removeItem('last_name');
  //   sessionStorage.removeItem('first_name');
  //   sessionStorage.removeItem('company');
  //   sessionStorage.removeItem('is_archived');
  //   sessionStorage.removeItem('id');
  // }

  isTokenExpired() {
    let token = sessionStorage.getItem('token');
    if (!token) {
      return true;
    }
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;

    const date = Math.floor(Date.now() / 1000);

    return date > expiry;
  }
  // Méthode pour télécharger le logo de l'entreprise
  downloadLogo(company: string) {
    return this.http.get(`${environment.apiUrl}/downloadLogo/${company}`, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }
  // Méthode pour télécharger l'image de l'utilisateur
  dowloadImage(id: string) {
    return this.http.get(`${environment.apiUrl}/downloadImage/${id}`, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }


}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthService } from '../../service/auth/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.css'
})
export class NavbarComponent implements OnInit{

  first_name:any;
  last_name:any
  profile:any;
  image!: string;
  id_employee!: any;

  constructor(
    private authService:AuthService,
    private router:Router,
  ){}
  ngOnInit(): void {

    this.profile=sessionStorage.getItem('profile');
    this.first_name=sessionStorage.getItem('first_name');
    this.last_name=sessionStorage.getItem('last_name');
    this.id_employee=sessionStorage.getItem('id_employee');

    this.downloadLogo(this.id_employee);

  }
  downloadLogo(id_employee: string): void {
    this.authService.dowloadImage(id_employee).subscribe(
      (imageBlob: Blob) => {
        // Convertir le Blob en URL d'image
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result as string;
          this.image = imageUrl;
        };
        reader.readAsDataURL(imageBlob);
      },
      (error) => {
        console.error('Erreur lors du téléchargement du logo :', error);
      }
    );
  }


  logout(){
    this.authService.logout(this.id_employee).subscribe(data => {
      Swal.fire({
        icon:'success',
        title: 'Bye Bye!',
        showConfirmButton: false,
        timer: 1000,
      });
        this.router.navigate(['/login'])
    });

  }

}
